<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="PlusCircleIcon"
              icon-right
              :statistic="currency(deposit)"
              statisticTitle="DEPOSIT BALANCE"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="MinusCircleIcon"
              icon-right
              :statistic="currency(withdraw)"
              statisticTitle="WITHDRAW BALANCE"
              color="danger"
            />
          </div>
        </div>

      </div>
    </div>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          {{ checkusebank }}
          <!-- COMPUTED STORE CHECK USE BANK  DO NOT DELETE BY LILSHARK-->
          <div class="vx-card__title">
            <h4>เลือกวันที่</h4>
            <br />
          </div>
          <flat-pickr v-model="date" />
          <p class="mt-4">Selected Date: {{ date }}</p>
        </div>
      </div>
      <vs-table stripe max-items="20" pagination :data="memberData" search>
        <template slot="thead">
          <vs-th>วัน/เวลา</vs-th>
          <vs-th>ช่องทาง</vs-th>
          <vs-th>ยอดเงิน</vs-th>
          <vs-th>ORDER ID</vs-th>
          <vs-th>USERNAME</vs-th>
          <vs-th>สถานะการทำรายการ</vs-th>
          <vs-th>สถานะแอดเครดิต</vs-th>
          <vs-th>จัดการ</vs-th>
          <vs-th></vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.datetime">
              {{ moment(tr.datetime).format("YYYY-MM-DD HH:mm:ss") }}
            </vs-td>

            <vs-td :data="tr.trans_type">
              7P
            </vs-td>
            <vs-td :data="tr.amount">
              {{ currency(tr.amount) }}
            </vs-td>
            <vs-td :data="tr.amount">
              {{ data[indextr].order_id }}
            </vs-td>
            <vs-td class="text-danger"
              ><router-link :to="`/member/${data[indextr].username}`">{{
                data[indextr].username
              }}</router-link>
            </vs-td>
            <!-- Status -->
            <vs-td
              :class="
                data[indextr].status == 1
                  ? 'text-success'
                  : data[indextr].status == 2 || data[indextr].status == 3
                  ? 'text-warning'
                  : data[indextr].status == 4
                  ? 'text-danger'
                  : null
              "
            >
              {{ check_status(data[indextr].status) }}
            </vs-td>
            <vs-td
              :class="
                data[indextr].status_addcredit == 1
                  ? 'text-success'
                  : data[indextr].status_addcredit == 2 ||
                    data[indextr].status_addcredit == 3
                  ? 'text-warning'
                  : data[indextr].status_addcredit == 98 ||
                    data[indextr].status_addcredit == 4
                  ? 'text-danger'
                  : null
              "
            >
              {{ check_status_addcredit(data[indextr].status_addcredit) }}
            </vs-td>

            <!-- Status END -->
            <!-- button -->
            <vs-td
              v-if="
                data[indextr].status_addcredit == 0 &&
                  profile.bank_show == 0 &&
                  data[indextr].status == 1
              "
              ><vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="border"
                text-color="white"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >กำลังรอทำรายการ
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 2 && data[indextr].status == 1
              "
              ><vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 3 && data[indextr].status == 1
              "
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 4 && data[indextr].status == 1
              "
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 5 && data[indextr].status == 1
              "
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 6 && data[indextr].status == 1
              "
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 7 && data[indextr].status == 1
              "
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td
              v-else-if="
                data[indextr].status_addcredit == 8 && data[indextr].status == 1
              "
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ยังไม่ใช้งาน
              </vs-button></vs-td
            >
            <vs-td v-else></vs-td>
            <vs-td
              v-if="
                data[indextr].status_addcredit == 0 && profile.bank_show == 0
              "
              ><vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="danger"
                type="border"
                icon="description"
                @click="input_cancel(tr.order_id, tr.amount)"
                >ยกเลิก
              </vs-button></vs-td
            >
            <vs-td v-else-if="data[indextr].status_addcredit == 2"
              ><vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="danger"
                type="filled"
                icon="description"
                @click="input_cancel(tr.order_id, tr.amount)"
                >ยกเลิก
              </vs-button></vs-td
            >
            <vs-td v-else-if="data[indextr].status_addcredit == 3"
              ><vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="danger"
                type="filled"
                icon="description"
                @click="input_cancel(tr.order_id, tr.amount)"
                >ยกเลิก
              </vs-button></vs-td
            >
            <vs-td v-else-if="data[indextr].status_addcredit == 4"
              ><vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="danger"
                type="filled"
                icon="description"
                @click="input_cancel(tr.order_id, tr.amount)"
                >ยกเลิก
              </vs-button></vs-td
            >
            <vs-td v-else-if="data[indextr].status != 1 && data[indextr].status !=3 "
              >
              <vs-row>
                <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="success"
                type="filled"
                icon="description"
                @click="input_username(tr.order_id, tr.amount)"
                >ใช้ยอด
              </vs-button>
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.payment.action
                    ? false
                    : true
                "
                size="small"
                color="danger"
                type="filled"
                icon="description"
                class="mx-2"
                @click="input_cancel(tr.order_id, tr.amount)"
                >ยกเลิก
              </vs-button>
              </vs-row>

            </vs-td>

            <vs-td v-else />
            <!-- button end-->
          </vs-tr>

        </template>
      </vs-table>
    </div>
    <!-- popup_usebank -->
    <vs-popup
      classContent="popup-example"
      title="Username"
      :active.sync="popup_usebank"
    >
      <br />
      <vx-input-group class="mb-base">
        <template slot="prepend">
          <div class="prepend-text bg-primary">
            <span>Username</span>
          </div>
        </template>
        <vs-input placeholder="Username" v-model="username" />
      </vx-input-group>
      <div class="mt-2">
          <vs-input class="inputx" placeholder="กรุณาวางรูป" :value="selectedFile.name" @paste="onPaste"/>
          <vs-checkbox disabled="true" class="mt-2" :color="chk_box.color" v-model="chk_box.data">{{chk_box.text}}</vs-checkbox>
          <canvas  style="border:1px solid grey;" id="mycanvas" width="100" height="100" ></canvas>
      </div>
      <br />
      <p v-if="this.username === ''">
        <small class="text-danger">- กรุณาใส่ Username ก่อนกดปุ่ม OK</small>
      </p>
      <p v-else><small class="text-success">- ใส่ Username สำเร็จ </small></p>

      <vs-button
        @click="use_bank(order_id, amount)"
        color="primary"
        type="filled"
        class="mt-2"
        :disabled="!username"
        >OK</vs-button
      >
    </vs-popup>
    <!-- popup_cancel -->
    <vs-popup
      classContent="popup-example"
      title="Username"
      :active.sync="popup_cancel"
    >
      <br />
      <h3 class="text-center">
        ยกเลิกยอดฝาก <br />
        จำนวนเงิน {{ amount }}
      </h3>
      <br />
      <vs-button
        @click="use_cancel(order_id, amount)"
        color="primary"
        type="filled"
        >ยืนยัน</vs-button
      >
    </vs-popup>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

import axios from '../../axios'
import moment from 'moment'
import store from '../../store/store'
import * as imageConversion from 'image-conversion'

export default {
  components: {
    vSelect,
    flatPickr,
    StatisticsCardLine
  },
  data () {
    return {
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      listdata: [],
      status: '',
      tid: '',
      amount: '',
      trans_no: '',
      trans_info: '',
      order_id: '',
      trans_amount: '',
      username: '',
      profile: {
        full_name: '',
        bank_number: '',
        sum_deposit: '',
        sum_withdraw: '',
        balance: '',
        bank_show: 0,
        bank_run: 0
      },
      popup_usebank: false,
      popup_cancel: false,
      events: ['onChange'],
      config: {},
      memberData: [],
      components: '',
      status_usebank: [],
      selectedFile: [],
      chk_box: { text: 'กรุณาวางรูป', color: 'danger', data: false },
      deposit: 0,
      withdraw: 0
    }
  },
  computed: {
    // paginationPageSize () {
    //   if (this.gridApi) return this.gridApi.paginationGetPageSize()
    //   else return 10
    // },
    // totalPages () {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages()
    //   else return 0
    // },
    // currentPage: {
    //   get () {
    //     if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
    //     else return 1
    //   },
    //   set (val) {
    //     this.gridApi.paginationGoToPage(val - 1)
    //   }
    // },
    checkusebank () {
      if (this.$store.state.StatusBank.status === true) {
        this.selectdate()
        store.commit('SET_STATUS_USEBANK', false)
      }
      return ''
    }
  },
  watch: {
    date (newValue) {
      this.date = newValue
      this.selectdate()
    },
    selectedFile () {
      if (this.selectedFile.name.length > 0) {
        this.chk_box.text = 'วางรูปสำเร็จ'
        this.chk_box.color = 'success'
        this.chk_box.data = true
      } else {
        this.chk_box.text = 'วางรูปไม่สำเร็จ'
        this.chk_box.color = 'danger'
        this.chk_box.data = false
      }
    }
  },
  async mounted () {
    await this.selectdate()
    await this.getBalance()
  },
  methods: {
    async getBalance () {
      try {
        const getBalance = await axios
          .post('/7p/api/balance')

        if (getBalance.data.status.success) {
          const { balance, balance_withdraw } = getBalance.data.data
          this.deposit = balance
          this.withdraw = balance_withdraw
        }
      } catch (e) {
        console.error(e)
      }
    },
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(Math.abs(amount))
        return `-${amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    status_color (params) {
      if (params.data.addcredit_status === 1) {
        return {
          color: 'green'
        }
      } else if (params.data.addcredit_status === 4) {
        return {
          color: 'red'
        }
      } else if (
        params.data.addcredit_status !== 0 &&
        params.data.addcredit_status !== 1 &&
        params.data.addcredit_status !== 4
      ) {
        return {
          color: 'orange'
        }
      }
    },
    check_status (status) {
      if (status === 0) {
        return 'รอการโอนเงิน'
      } else if (status === 1) {
        return 'ทำรายการสำเร็จ'
      } else if (status === 2) {
        return 'ไม่ทำรายการในเวลาที่กำหนด'
      } else if (status === 3) {
        return 'ยกเลิกการทำรายการ'
      } else if (status === 4) {
        return 'REFUND จาก PAYMENT'
      } else if (status === 5) {
        return 'เลขบัญชีที่โอนไม่ตรงกับสมัคร'
      }
    },
    check_status_addcredit (status) {
      if (status === 0) {
        return 'กำลังรอทำรายการ'
      } else if (status === 1) {
        return 'ทำรายการสำเร็จ'
      } else if (status === 2) {
        return 'ติดสถานะรับโบนัส'
      } else if (status === 3) {
        return 'หายูสเซอร์ไม่พบ'
      } else if (status === 4) {
        return 'ทำรายการผิดพลาด'
      } else if (status === 5) {
        return 'ติดสถานะยิงปลา'
      } else if (status === 6) {
        return 'ติดสถานะหวย'
      } else if (status === 7) {
        return 'มีรายการแทงค้างอยู่'
      } else if (status === 8) {
        return 'ติดสถานะอั้นถอน'
      } else if (status === 98) {
        return 'ยกเลิกรายการฝาก'
      } else {
        return '-'
      }
    },

    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    async white () {
      await axios
        .post('bank/white', {
          bank: this.$route.params.bank,
          id: this.$route.params.id
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขยอดขาวไม่สำเร็จ',
          text: this.status.info
        })
      }
    },
    async red () {
      await axios
        .post('bank/red', {
          bank: this.$route.params.bank,
          id: this.$route.params.id
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขยอดแดงไม่สำเร็จ',
          text: this.status.info
        })
      }
    },
    async selectdate () {
      await axios
        .get(`7p/list/${this.date}`)
        .then(response => (this.memberData = response.data))
    },
    input_username (order_id, amount) {
      this.username = ''
      this.order_id = order_id
      this.amount = amount
      this.popup_usebank = true
    },
    async use_bank (order_id, amount) {
      this.popup_usebank = false
      if (this.selectedFile.size > 0) {
        await axios
          .post('7p/usepayment', {
            username: this.username,
            order_id,
            amount
          })
          .then(response => (this.status_usebank = response.data))
        await this.onUploadFile()
        this.$vs.notify({
          time: this.status_usebank.status === true ? 3000 : 8000,
          color: this.status_usebank.status === true ? 'success' : 'danger',
          position: 'top-right',
          icon: this.status_usebank.status === true ? 'check_box' : 'error',
          title: this.status_usebank.status === true ? 'ใช้ยอดสำเร็จ' : 'ใช้ยอดไม่สำเร็จ',
          text: this.status_usebank.status === true ? `${this.status_usebank.info}` : `${this.status_usebank.info}`
        })
        if (this.status_usebank.status === true) {
          await axios.post('/line/bank', {
            username: this.username,
            balance: amount,
            admin: this.$store.state.AppActiveUser.username
          })
          setTimeout(() => {
            window.location.reload()
          }, 1500)
          await store.commit('SET_STATUS_USEBANK', true)
        }
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: 'กรุณาอัพสลิปหรือภาพที่เกี่ยวข้องกับการเพิ่มเครดิต'
        })
      }
    },
    input_cancel (order_id, amount) {
      this.username = ''
      this.order_id = order_id
      this.amount = amount
      this.popup_cancel = true
    },
    async use_cancel (order_id) {
      this.popup_cancel = false
      await axios
        .post('7p/canceldeposit', {
          bank: 'fast_payment',
          username: this.username,
          order_id
        })
        .then(response => (this.status_usecancel = response.data))
      this.$vs.notify({
        time: this.status_usecancel.status === true ? 3000 : 8000,
        color: this.status_usecancel.status === true ? 'success' : 'danger',
        position: 'top-right',
        icon: this.status_usecancel.status === true ? 'check_box' : 'error',
        title:
          this.status_usecancel.status === true ? 'ใช้ยอดสำเร็จ' : 'ใช้ยอดไม่สำเร็จ',
        text: this.status_usecancel.status === true ? `${this.status_usecancel.info}` : `${this.status_usecancel.info}`
      })

      await store.commit('SET_STATUS_USEBANK', true)
    },
    onFileChange (e) {
      const selectedFile = e.target.files[0]
      this.selectedFile = selectedFile
    },
    async compressFile () {
      const res = await imageConversion.compress(this.selectedFile, {
        size: 100,
        quality: 0.5,
        type: 'image/jpg'
        // width: 300,
        // height: 200,
        // scale: 0.5
      })
      this.selectedFile = res
    },
    async  onUploadFile () {
      await this.compressFile()
      const formData = new FormData()
      formData.append('path', 'payment')
      formData.append('file', this.selectedFile)
      formData.append('topupid', this.order_id) // appending file

      // sending file to the backend
      const respone = await axios
        .post('aws3/upload/upload', formData)
        .then()
        // .catch(err => { console.log('errja', err) })
      //   console.log(respone)
      if (respone.status === 200 && respone.data) {
        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: 'อัพโหลดรูปสำเร็จ',
          text: 'อัพโหลดรูปสำเร็จ'
        })
      }
    },
    retrieveImageFromClipboardAsBlob (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      const items = pasteEvent.clipboardData.items

      if (items === undefined) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf('image') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()

        if (typeof callback === 'function') {
          callback(blob)
          this.selectedFile = blob
        }
      }
    },
    onPaste (evt) {
      // console.log('on paste', evt)
      // Handle the event
      this.retrieveImageFromClipboardAsBlob(evt, function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob) {
          const canvas = document.getElementById('mycanvas')
          const ctx = canvas.getContext('2d')

          // Create an image to render the blob on the canvas
          const img = new Image()

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image


            // Draw the resized image
            const resizedWidth = 300 // Adjust the resized width as needed
            const resizedHeight = (img.height / img.width) * resizedWidth

            canvas.width = resizedWidth
            canvas.height = resizedHeight

            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
            // Draw the image


            // Display a smaller version of the image
            const thumbnailCanvas = document.getElementById('thumbnailCanvas')
            const thumbnailCtx = thumbnailCanvas.getContext('2d')

            const thumbnailSize = 100 // Adjust the size as needed
            thumbnailCanvas.width = thumbnailSize
            thumbnailCanvas.height = thumbnailSize
            thumbnailCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, thumbnailSize, thumbnailSize)
          }

          // Crossbrowser support for URL
          const URLObj = window.URL || window.webkitURL

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob)
        }
      })
    }
  }
}
</script>
